import React from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { StaticImage } from "gatsby-plugin-image"
//DATA
//STYLES
import { H2, LabelBW, Text } from "../utils/typo"
import { ContainerMob, Section2 } from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"

const Sec = styled(Section2)`
  :nth-of-type(2) {
    background-color: ${({ theme }) => theme.color.sec5};
    p,
    h2,
    label {
      color: ${({ theme }) => theme.color.whiteb};
    }
  }
`

const Wrapper = styled(ContainerMob)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  overflow: hidden;
  align-items: center;

  ${down("sm")} {
    grid-template-columns: 1fr;
  }
`
const InfoWrapper = styled.div``

const Dzialanie = () => {
  return (
    <div>
      <Seo title="Działanie" slug="/dzialanie" />

      <Sec
        pt={6}
        pb={6}
        tabIndex="0"
        role="contentinfo"
        aria-label="Informacje o działaniu systemu Therm Check"
      >
        <Wrapper>
          <InfoWrapper>
            <LabelBW mb="2rem">Działanie</LabelBW>
            <H2 mb="1rem" lineHeight="1.1">
              THERM Check - wykrywanie stanów gorączkowych
            </H2>
            <Text weight="700">
              — Pomiar temperatury z użuciem sztucznej inteligencji
            </Text>
            <Text mt="2rem">
              System <b>THERM Check</b> wykorzystuje wysoce zaawansowaną
              technologię pomiaru temperatury z użyciem
              <b>sztucznej inteligencji.</b> Pomiaru dokonują jednocześnie dwie
              kamery: <b>video RGBD</b> (z możliwością pomiaru odległości
              obiektu od kamery) oraz <b>termowizyjna</b>, bardzo wysoką
              dokładność wyników umożliwia zastosowanie
              <b>ciała czarnego pasywnego</b> (źródło temperatury
              referencyjnej), dzięki zastosowaniu sztucznej inteligencji system
              potrafi sam wykryć źródło temperatury odniesienia oraz jego
              odległość od kamery (nie ma potrzeby ręcznego ustawienia oraz
              sprawdzenia poprawności).
            </Text>
          </InfoWrapper>

          <StaticImage
            placeholder="blurred"
            alt="Projektowanie graficzne - ikona"
            src="../assets/images/TC4.png"
            layout="fullWidth"
            objectFit="contain"
          />
        </Wrapper>
      </Sec>

      <Sec pt={6} pb={6}>
        <Wrapper>
          <StaticImage
            placeholder="blurred"
            alt="Projektowanie graficzne - ikona"
            src="../assets/images/TC1.png"
            layout="fullWidth"
            objectFit="contain"
          />
          <InfoWrapper>
            <LabelBW mb="2rem">Pomiar temperatury</LabelBW>
            <H2 mb="1rem" lineHeight="1.1">
              Pomiar temperatury
            </H2>
            <Text weight="700">— Mierzenie ciepłoty na powierzchni oka</Text>
            <Text mt="2rem">
              Całkowicie innowacyjnym i unikatowym na rynku rozwiązaniem jest
              mierzenie temperatury ciała na powierzchni oka, które jest
              najbardziej obiektywnym miejscem pomiaru oraz najwierniej
              zbliżonym do temperatury wewnętrznej ciała człowieka. Jest to
              również możliwe dzięki użyciu sztucznej inteligencji, która
              natychmiast wykrywa ludzką twarz, jej odległość od kamery i
              lokalizuje oczy (istnieje możliwość nie tylko wykrycia twarzy, a
              nawet jej rozpoznania). Dużym atutem systemu jest czas – cała
              procedura wykrycia twarzy oraz pomiaru i rejestracji temperatury
              jest niezwykle szybka (ok. 0,5s) i pozwala na tworzenie raportów,
              archiwizację i analizę wyników.
            </Text>
          </InfoWrapper>
        </Wrapper>
      </Sec>

      <Sec pt={6} pb={6}>
        <Wrapper>
          <InfoWrapper>
            <LabelBW mb="2rem">Możliwe zastosowania</LabelBW>
            <H2 mb="1rem" lineHeight="1.1">
              System sprawdzi się wszędzie tam gdzie:
            </H2>

            <ul>
              <li>ważny jest czas mierzenia temperatury</li>
              <li>istotna jest dokładnośc pomiaru</li>
              <li>chcemy uniknąc kontsaktu bezpośredniego</li>
              <li>przepustowość systemu ma duże znaczenie</li>
              <li>
                może być potrzebny raport wyników oraz rozpoznawanie twarzy
              </li>
            </ul>
          </InfoWrapper>

          <StaticImage
            placeholder="blurred"
            alt="Projektowanie graficzne - ikona"
            src="../assets/images/TC5.png"
            layout="fullWidth"
            objectFit="contain"
          />
        </Wrapper>
      </Sec>
    </div>
  )
}
export default Dzialanie
